import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(private _http: HttpClient) {}

  // send OTP
  async sendOtp(body: { input: string; type: string }) {
    return await firstValueFrom(
      this._http.post(`${environment.api_url}users/send-otp`, body)
    );
  }

  // verify OTP
  async verifyOtp(body: { otp: string; userId: number; action: 'Login' }) {
    return await firstValueFrom(
      this._http.post(`${environment.api_url}users/verify-otp`, body)
    );
  }

  // resend OTP
  async resendOtp(body: { userId: number; type: 'phone'; action: 'Login' }) {
    return await firstValueFrom(
      this._http.post(`${environment.api_url}users/resend-otp`, body)
    );
  }

  // delete account
  deleteAccount(): Observable<any> {
    return this._http.delete(
      `${environment.api_url}users/me`,
      this.userToken()
    );
  }

  //create header with user token
  userToken() {
    return {
      headers: { Authorization: `Bearer ${localStorage.getItem('Token')}` },
    };
  }
}
